import React, { useEffect } from "react";

export const wrapPageElement = ({ element }) => {
  useEffect(() => {
    const queryParams = sessionStorage.getItem("queryParams");
    if (queryParams) {
      document.querySelectorAll("a[href^='/']").forEach((link: HTMLAnchorElement) => {
        const url = new URL(link.href, window.location.origin);
        url.search = queryParams;
        link.href = url.toString();
      });
    }
  }, []);

  return (
    <>
      {element}
      <script
        key={`pardot-tracking-script`}
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            piAId = '1026713';
            piCId = '57553';
            piHostname = 'resources.edifecs.com';
            (function() {
              function async_load(){
                var s = document.createElement('script'); s.type = 'text/javascript';
                s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
                var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
              }
              if(window.attachEvent) { window.attachEvent('onload', async_load); }
              else { window.addEventListener('load', async_load, false); }
            })();
          `,
        }}
      />
    </>
  );
};
