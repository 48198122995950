import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

import "./src/assets/main.scss";

export { wrapPageElement } from "./static/siteScripts";

export const onClientEntry = () => {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.toString()) {
    sessionStorage.setItem("queryParams", queryParams.toString());
  }
};
